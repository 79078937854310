import request from '@/service/lib/request';
// 文章相关

/**
 * 星火模型
 * @author lb
 */
export function xhContent(data) {
    return request({
      url: '/headlines/xh_content',
      method: 'post',
      data
    });
}

/**
 * 标题-分页
 * @author lb
 */
export function aiTitlePage(data) {
  return request({
    url: '/headlines/ai_title_page',
    method: 'post',
    data
  });
}

/**
 * 阿里大模型-异步
 * @author lb
 */
export function aliContentAsync(data) {
  return request({
    url: '/headlines/ali_content_async',
    method: 'post',
    data
  });
}

/**
 * 阿里大模型
 * @author lb
 */
export function aliContent(data) {
  return request({
    url: '/headlines/ali_content',
    method: 'post',
    data
  });
}

/**
 * 内容目录压缩包下载
 * @author lb
 */
export function contentDirectoryDownload(data) {
  return request({
    url: '/headlines/content_directory_download',
    method: 'post',
    responseType: 'blob',
    data
  });
}

/**
 * 内容目录删除
 * @author lb
 */
export function contentDirectoryDelete(data) {
  return request({
    url: '/headlines/content_directory_delete',
    method: 'post',
    data
  });
}

/**
 * AI内容-分页
 * @author lb
 */
export function contentAiPage(data) {
  return request({
    url: '/headlines/content_ai_page',
    method: 'post',
    data
  });
}

/**
 * AI内容-修改
 * @author lb
 */
export function updateContentAi(data) {
  return request({
    url: '/headlines/update_content_ai',
    method: 'post',
    data
  });
}

/**
 * 根据文章ID合成视频
 * @author lb
 */
export function margeVideoByContentId(data) {
  return request({
    url: '/headlines/marge_video_by_id',
    method: 'post',
    data
  });
}

/**
 * 视频合成记录-分页
 * @author lb
 */
export function videoComposePage(data) {
  return request({
    url: '/headlines/video_compose_page',
    method: 'post',
    data
  });
}

/**
 * 视频合成下载
 * @author lb
 */
export function videoComposeDownload(data) {
  return request({
    url: '/headlines/video_compose_download',
    method: 'post',
    responseType: 'blob',
    data
  });
}
