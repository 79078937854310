<template>
  <div class="main-conent main-conent-screen main-conent-bgFFF main-conent-borderradius">
    <div class="screen-header">
        <el-row>
            <el-col :span="8">
                <el-button type="primary" icon="el-icon-plus" @click="handleAdd">输入内容</el-button>
            </el-col>
        </el-row>              
    </div>

    <div class="content">
      <el-input id="content" v-model="content" type="textarea" disabled></el-input>
      <el-button @click="() => handleCopy(this.content)" type='primary'>复制</el-button>
    </div>
    
    <!-- 添加或修改对话框 -->
    <el-dialog :title='title' :visible.sync='open' width='500px' :before-close='cancel'>
        <el-form ref='form' :model='form' label-width='130px'>
            <el-form-item label='AI类型' prop='radio'>
              <el-radio v-model="form.radio" label="1">讯飞</el-radio>
              <el-radio v-model="form.radio" label="2">阿里</el-radio>
            </el-form-item>
            <el-form-item label='内容' prop='content'>
                <el-input v-model='form.content' placeholder='请输入内容' type="textarea" :rows="3"/>
            </el-form-item>
        </el-form>
        <div slot='footer' class='dialog-footer'>
        <el-button type='primary' @click='submitForm'>确 定</el-button>
        <el-button @click='cancel'>取 消</el-button>
        </div>
    </el-dialog>
  </div>
</template>
<script>

import { xhContent, aliContent } from '@/service/headlines';

export default {
  data () {
    return {
      // 查询参数
      queryParams: {
        pageNo: 1,
        pageSize: 10,
        search: undefined
      },
      total: 0,
      input1: '',
      svalue2: '',
      searchDrawer: false,
      content: '',
      tableData: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 表单参数
      form: {
        radio: "1"
      }
    };
  },
  created() {
    this.content = "";
  },
  methods: {
    handleClick(row) {
      console.log(row);
    },
    handleAdd() {
        this.title = '新增';
        this.open = true;
        this.form = {
          radio: "1"
        };
    },
    tableEdit(row){
        this.title = '修改';
        this.open = true;
        this.form = {...row};
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.resetForm('form');
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          if(this.form['radio'] === '2'){
            aliContent(this.form).then(response => {
              if (response.code === 0) {
                this.cancel();
                this.content = response.data;
              } else {
                this.$message({
                    type: 'info',
                    message: '异常'
                });  
              }
            });
          } else {
            xhContent(this.form).then(response => {
              if (response.code === 0) {
                this.cancel();
                this.content = response.data;
              } else {
                this.$message({
                    type: 'info',
                    message: '异常'
                });  
              }
            });
          }
        }
      });
    }
  }
};
</script>
<style>
.content{
  width: 60%;
  margin-left: 20%;
}
</style>